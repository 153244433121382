// components/OnlineStatusChecker.tsx
'use client';

import { useEffect } from 'react';
// import { useRouter } from 'next/router';

const OnlineStatusChecker = () => {
  // const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleOnlineStatus = () => {
        if (!navigator.onLine && window.location.pathname.indexOf('/internet-lost') === -1) {
          // router.push('/error-page/internet-lost');
          window.location.href = '/error-page/internet-lost';
        }
      };

      window.addEventListener('offline', handleOnlineStatus);
      window.addEventListener('online', handleOnlineStatus);

      // Check status when component mounts
      handleOnlineStatus();

      return () => {
        window.removeEventListener('offline', handleOnlineStatus);
        window.removeEventListener('online', handleOnlineStatus);
      };
    }
  }, []);

  return null;
};

export default OnlineStatusChecker;
