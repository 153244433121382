// TODO: รอทำเปลี่ยน Theme อีกที
// import typo from './design-token-un-used/typography.json';
// import lightSemantic from './design-token/Semantic.json';
// import darkSemantic from './design-token/Semantic-Dark.json';
import '@/tokens/build/tokens.js';
import Atomics from '@/tokens/Atomic.Light.tokens.json'
import * as Tokens from '@/tokens/build/tokens';
import { Checkbox, Tooltip } from 'antd';

// export let semantic = lightSemantic;

function createTheme(mode: 'light' | 'dark'): { token: any, components: any } {
  // const s = semantic = mode === 'light' ? lightSemantic : darkSemantic;

  return {
    token: {
      colorPrimary: Tokens.SemanticTextGeneralStandardHighlight,
      colorSecondary: Tokens.SemanticSurfaceCardTextBackgroundSecondary,
      colorPrimaryBg: Tokens.SemanticSurfaceCardTextBackgroundPrimary,
      colorBgLayout: Tokens.SemanticBackgroundGeneralStandardDefault,
      colorSuccess: Tokens.SemanticTextGeneralStandardSuccess,
      colorWarning: Tokens.SemanticTextGeneralStandardWarning,
      colorError: Tokens.SemanticTextGeneralStandardCritical,
      colorInfo: Tokens.SemanticTextGeneralStandardHighlight,
      colorLink: Tokens.SemanticTextButtonLinkPrimaryEnabled,
      colorTextBase: Tokens.SemanticTextGeneralStandardDefault,
      colorHighlight: Tokens.SemanticTextGeneralStandardHighlight,
      colorBgBase: Tokens.SemanticBackgroundGeneralStandardDefault,
      colorBgContainer: Tokens.SemanticBackgroundGeneralStandardDefault,
      colorText: Tokens.SemanticTextGeneralStandardDefault,
      colorTextSecondary: Tokens.SemanticTextGeneralStandardSubdued,
      fontSizeBase: pxToNumber(Atomics.FontSize.font14.$value),
      fontSize: pxToNumber(Atomics.FontSize.font14.$value),
      fontFamily: `${Atomics.FontFamily.Inter.$value}, Noto Sans Thai`,
      fontFamilyHeading: `${Atomics.FontFamily.Poppins.$value}, 'Noto Sans Thai'`,
      fontFamilyBody: `${Atomics.FontFamily.Inter.$value}, 'Noto Sans Thai'`,
      fontFamilySecondary: `'${Atomics.FontFamily.Sarabun.$value}', 'Noto Sans Thai'`, // for <Description>
      fontWeight: Atomics.FontWeight.Regular.$value,
      fontSizeSM: Atomics.FontSize.font14.$value,
      fontSizeLG: Atomics.FontSize.font24.$value,
      fontSizeXL: Atomics.FontSize.font32.$value,
      fontSizeHeading1: Atomics.FontSize.font36.$value,
      fontSizeHeading2: Atomics.FontSize.font32.$value,
      fontSizeHeading3: Atomics.FontSize.font24.$value,
      fontSizeHeading4: Atomics.FontSize.font20.$value,
      fontSizeHeading5: Atomics.FontSize.font18.$value,
      lineHeight: 1.4,
      lineHeightLG: 1.48,
      lineHeightSM: 1.4,
      paddingXXS: pxToNumber(Tokens.SpaceSpace4px),
      paddingXS: pxToNumber(Tokens.SpaceSpace8px), // <Space> small
      paddingSM: pxToNumber(Tokens.SpaceSpace8px),
      padding: pxToNumber(Tokens.SpaceSpace16px), // <Space> middle
      paddingMD: pxToNumber(Tokens.SpaceSpace20px),
      paddingLG: pxToNumber(Tokens.SpaceSpace24px), // <Space> large
      paddingXL: pxToNumber(Tokens.SpaceSpace32px),
      marginXXS: pxToNumber(Tokens.SpaceSpace4px),
      marginXS: pxToNumber(Tokens.SpaceSpace8px),
      marginSM: pxToNumber(Tokens.SpaceSpace8px),
      margin: pxToNumber(Tokens.SpaceSpace16px),
      marginMD: pxToNumber(Tokens.SpaceSpace20px),
      marginLG: pxToNumber(Tokens.SpaceSpace24px),
      marginXL: pxToNumber(Tokens.SpaceSpace40px),
      marginXXL: pxToNumber(Tokens.SpaceSpace64px),
      sizeXXL: pxToNumber(Tokens.SpaceSpace48px),
      sizeXL: pxToNumber(Tokens.SpaceSpace32px),
      sizeLG: pxToNumber(Tokens.SpaceSpace24px),
      sizeMD: pxToNumber(Tokens.SpaceSpace20px),
      sizeMS: pxToNumber(Tokens.SpaceSpace16px),
      size: pxToNumber(Tokens.SpaceSpace16px),
      sizeSM: pxToNumber(Tokens.SpaceSpace12px),
      sizeXS: pxToNumber(Tokens.SpaceSpace8px),
      sizeXXS: pxToNumber(Tokens.SpaceSpace4px),
      borderRadiusLG: pxToNumber(Tokens.SpaceSpace8px),
      colorBorder: Tokens.SemanticBorderGeneralStandardDefault, // BORDER on active
      colorBorderSecondary: Tokens.SemanticBorderGeneralStandardDefault, // BORDER unActive
    },
    components: {
      Button: {
        colorPrimary: Tokens.SemanticActionButtonFilledPrimaryEnabled,
        colorPrimaryHover: Tokens.SemanticActionButtonFilledPrimaryHovered,
        colorPrimaryActive: Tokens.SemanticActionButtonFilledPrimaryFocused,
        // primaryColor: getAtomicValue(s.Semantic.Text.ButtonFilled.Primary.Enabled.value),
        // borderColor: getAtomicValue(s.Semantic.Border.ButtonFilled.Primary.Enabled.value),
        // colorText: getAtomicValue(s.Semantic.Text.ButtonOutlined.Primary.Enabled.value),
      },
      Card: {
        padding: 0,
        marginXS: 0,
        headerHeight: 33,
        headerFontSize: Tokens.SemanticTextTableGeneralSubdued,
        headerBg: Tokens.SemanticSurfaceTableBackgroundTableHead
      },
      Divider: {
        colorSplit: Tokens.SemanticBorderNavigationBarStandardDefault
      },
      Input: {
        colorPrimary: Tokens.SemanticDropShadowTextInputStandardFocused,
        colorBorder: Tokens.SemanticBorderSearchBoxFocused
        // algorithm: true, // Enable algorithm
      },
      Switch: {
        // colorPrimary: 'pink',
        // colorSecondary: 'pink',
        colorPrimaryHover: 'pink',
        borderColor: 'black',
        // algorithm: true,
      },
      Select: {
        // colorBgElevated: colors.White.w100,
        // optionSelectedBg: colors.LightGrey.lg200,
      },
      // Descriptions: {
      //   paddingSM: '20px',
      //   paddingXS: '0px',
      //   colon: false,
      // },
      Table: {
        cellFontSize: Tokens.TableRowText.fontSize,
        cellFontSizeMD: Tokens.TableRowText.fontSize,
        cellFontSizeSM: Tokens.TableRowText.fontSize,
        cellPaddingBlock: 0,
        cellPaddingBlockSM: 20,
        cellPaddingBlockMD: 20,
        headerBg: Tokens.SemanticBackgroundGeneralStandardDefault,
        headerColor: Tokens.SemanticTextTableGeneralDefault,
      },
      List: {
        headerBg: '#eee' //Tokens.SemanticBackgroundGeneralStandardDefault,
      },
      Segmented: {
        fontFamily: `${Tokens.TabSwitchTabNameDefault.fontFamily}, Noto Sans Thai`,
        fontSize: Tokens.TabSwitchTabNameDefault.fontSize,
        itemColor: Tokens.SemanticTextTabSwitchTabNameDefault,
        itemSelectedBg: '#fff',//Tokens.SemanticSurfaceTabSwitchButtonCurrent,
        // itemSelectedColor: Tokens.SemanticTextTabSwitchTabNameCurrent,
        trackBg: Tokens.SemanticSurfaceTabSwitchBackgroundDefault,
        trackPadding: 2,
      },
      Tag: {
        defaultBg: Tokens.SemanticSurfaceColorTagStandardGrey,
        defaultColor: Tokens.SemanticTextColorTagStandardGrey,
        fontFamily: `${Tokens.TagTagSm.fontFamily}, Noto Sans Thai`,
        fontSize: pxToNumber(Tokens.TagTagSm.fontSize),
        fontSizeSM: pxToNumber(Tokens.TagTagSm.fontSize),
        borderRadiusSM: 16,
        lineHeightSM: 1.776,
      },
      Checkbox: {
        colorPrimary: Tokens.SemanticSurfaceTagCheckboxCheckedDefault
      },
      Tooltip: {
        colorBgSpotlight: Tokens.SemanticSurfaceTooltipLightDefault,
        colorTextLightSolid: Tokens.SemanticTextTooltipDescriptionLight
      },
    },
  };
}

export default createTheme;

function pxToNumber(px: string): number {
  return parseInt(px.replace('px', ''), 10);
}