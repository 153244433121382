// app/contexts/LocaleContext.tsx
'use client';

import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

type Locale = 'en' | 'th';
type ThemeType = 'light' | 'dark'

interface LocaleContextProps {
  locale: Locale;
  theme: ThemeType;
  switchLocale: (newLocale: Locale) => void;
  switchTheme: (newTheme: ThemeType) => void;
}

const LocaleContext = createContext<LocaleContextProps | undefined>({
  locale: 'en',
  theme: 'light',
  switchLocale: () => { },
  switchTheme: () => { },
});

export const LocaleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [locale, setLocale] = useState<Locale>('en');
  const [theme, setTheme] = useState<ThemeType>('dark');

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setTheme('light');
    }
  }, [])

  const switchLocale = (newLocale: Locale) => {
    setLocale(newLocale);
  };

  const switchTheme = (newTheme: ThemeType) => {
    setTheme(newTheme);
  };

  return (
    <LocaleContext.Provider value={{ locale, theme, switchLocale, switchTheme }}>
      {children}
    </LocaleContext.Provider>
  );
};

// export const useLocale = () => useContext(LocaleContext);

export const useLocale = (): LocaleContextProps => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }
  return context;
};
