'use client';

import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import enMessages from '../../../../locales/en.json';
import thMessages from '../../../../locales/th.json';
import { Layout } from 'antd';
import { flattenMessages } from '../../../utils/flattenMessages';
import dynamic from 'next/dynamic';
import { LocaleProvider, useLocale } from '@/app/context/LocaleContext';
import OnlineStatusChecker from '@/app/utils/onlineStatusChecker';
import createTheme from '@/app/styles/theme';

const MenuBuilder = dynamic(() => import('../../molecules/Main/MenuBuilder'), { ssr: false });

const messages = {
  en: flattenMessages(enMessages),
  th: flattenMessages(thMessages),
};

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <LocaleProvider>
      <ClientContent>{children}</ClientContent>
    </LocaleProvider>
  );
};

const ClientContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { locale, theme } = useLocale();
  let _theme = getTheme();
  
  return (
    <ConfigProvider theme={_theme}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {/* <Layout className={styles.layout}> */}
        <Layout>
          {/* <SiderMenu /> */}
          {/* <GlobalStyles /> */}
          <Layout style={{ padding: '0 0' }}>
            {children}
          </Layout>
          <OnlineStatusChecker />
        </Layout>
      </IntlProvider>
    </ConfigProvider>
  );

  function getTheme() {
    let _theme;

    if (theme === 'dark') {
      _theme = createTheme('dark');
    } else {
      _theme = createTheme('light');
    }
    
    return _theme;
  }
};

const SiderMenu: React.FC = () => (
  <Layout.Sider
    collapsible
    style={{ width: '500', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
  >
    <MenuBuilder />
  </Layout.Sider>
);

export default MainLayout;
